import $ from "jquery";
import React from "react"
import Layout from "./src/components/layout"

const loadScripts = () => {
  if ($(window).width() > 960 && $("#nav-container").length > 0) {
    $("#nav-container a").on("mouseover", function () {
      var position = $(this)
        .parent()
        .position()
      var width = $(this)
        .parent()
        .width()
      $("#nav-container .slide2")
        .css({
          opacity: 1,
          left: +position.left,
          width: width,
        })
        .addClass("squeeze")
    })

    $("#nav-container a").on("mouseout", function () {
      $("#nav-container .slide2")
        .css({ opacity: 0 })
        .removeClass("squeeze")
    })

    var currentWidth = $("#nav-container .nav")
      .find(".active")
      .parent("li")
      .width()
    var current = $(".nav .active").position()
    $("#nav-container .slide1").css({
      left: +current.left,
      width: currentWidth,
    })
  }
  $(document).ready(function () {
    $(`a[rel="prev"] span`).hide();
    $(`a[rel="next"] span`).hide();

    $(`nav li a[rel="prev"]`)
      .mouseover(() => {
        $(this).find(`a[rel="prev"] span`).show()
      })
      .mouseleave(() => {
        $(this).find(`a[rel="prev"] span`).hide()
      })

    $(`nav li a[rel="next"]`)
      .mouseover(() => {
        $(this).find(`a[rel="next"] span`).show()
      })
      .mouseleave(() => {
        $(this).find(`a[rel="next"] span`).hide()
      })

    $(".nav-open").click(() => {
      $("nav").hide()
    })
    $(".nav-close").click(() => {
      $("nav").show()
    })
  });
}

export const wrapRootElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onRouteUpdate = loadScripts;
export const onInitialClientRender = loadScripts;
export const shouldUpdateScroll = () => {
  window.scrollTo([0, 0])
  return true;
}