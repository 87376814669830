/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import "../stylesheets/layout.css"
import { Breakpoint } from "react-socks"

class Layout extends React.Component {
  render() {
    return (
      <>
        <Breakpoint customQuery="(min-width: 615px)">
          <Helmet>
            <script src="/hex.js" />
          </Helmet>
        </Breakpoint>
        <div id="hex-container"></div>
        <div className="main-container">
          <main className="overflow">{this.props.children}</main>
        </div>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout